import styled, { createGlobalStyle } from "styled-components";

export const supportDeviceSize = 1080;

export const device = {
  mobile: `max-width: 769px`,
};

export const GlobalStyle = createGlobalStyle`
html {
font-size: 62.5%; // 1rem = 10px 로 변경 한 것
// 참고링크 = https://stackoverflow.com/questions/68790660/setting-root-font-size-not-affecting-rem-units-in-safari-for-margin-padding-et
// 128px => 12.8rem , 4px => 0.4rem 가능!

    /* @media all and (max-width: ${supportDeviceSize}px) {
        font-size: 31.25%;
    } */
}

body {
    background: white;
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans KR', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}
fieldset, input, label {
    all: unset;
}
button {
    background: none;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
}
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 10rem 0 15.6rem;
  height: fit-content;

  @media all and (${device.mobile}) {
    padding: 4rem 2.4rem 15.6rem;
    box-sizing: border-box;
  }
`;

export const Container = styled.div`
  display: flex;
  max-width: 108rem;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;

  @media all and (${device.mobile}) {
    width: 100%;
    max-width: 72.1rem;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;
  cursor: pointer;
  /* aspect-ratio: 1; */

  @media all and (${device.mobile}) {
    width: 100%;
    max-width: 35.1rem;
    min-width: 0;
  }

  .cardImg {
    width: 100%;
    border-radius: 0.4rem;
    object-fit: contain;

    @media all and (${device.mobile}) {
      max-height: 35.1rem;
      height: inherit;
    }
  }

  .imgContainer {
    position: relative;
    width: 100%;
    background-color: #f4f4f4;

    @media all and (${device.mobile}) {
      max-height: 35.1rem;
      aspect-ratio: 1;
    }
  }
`;

export const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  .titleContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    white-space: normal;

    .title {
      color: #000;
      font-size: 2rem;
      font-weight: 500;
      line-height: 2.7rem;
      letter-spacing: -0.4px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 70%;

      @media all and (${device.mobile}) {
        max-width: 50%;
      }
    }
  }

  .content {
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 7rem;
    font-size: 2.4rem;
    font-weight: 700;
    width: 100%;
    letter-spacing: -0.48px;
  }
`;
