import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import React, { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import { GlobalStyle } from "./components/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import GoogleTagManager from "utils/GoogleTageManager";
import "react-quill/dist/quill.snow.css";
import ScrollToTop from "utils/ScrollToTop";

const loading = <div>화면을 불러오는 중 입니다.</div>;

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Page404 = React.lazy(() => import("./pages/page404/Page404"));
const Page500 = React.lazy(() => import("./pages/page500/Page500"));

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

const queryClient = new QueryClient();

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <GlobalStyle />
        <BrowserRouter>
          <GoogleTagManager />
          <ScrollToTop />
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/404" element={<Page404 />} />
              <Route path="/500" element={<Page500 />} />
              <Route path="/*" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </RecoilRoot>
    </QueryClientProvider>
  </StrictMode>
);
